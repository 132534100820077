.cadastroForm {
  display: grid;
  grid-template-columns: 10% 90%;
}

@media only screen and (max-width: 600px) {
  .cadastroForm {
    grid-template-columns: 100%;
  }
}
