.container {
  height: 100vh;
  padding: 20px;
  position: relative;
  h3 {
    padding: 2px 10px;
    margin: 0;
    background-color: #e0e0e0b7;
    border-radius: 3px;
  }
}

.queryArea {
  width: 100%;
  textarea {
    width: 100%;
    height: 100%;
    padding: 10px;
    font-size: large;
    border: 0;
    background-color: #cae4e44d;
    border-radius: 5px;
    margin-top: 10px;
    outline: none;
    transition: 0.1s ease;
    font-weight: 500;
    &:focus {
      background-color: #abdfdfc5;
      box-shadow: 0 0 5px 2px rgba(128, 128, 128, 0.171);
    }
  }
}

.outputArea {
  width: 100%;
  padding: 10px;
  background-color: #d3d3d34d;
  border-radius: 5px;
  overflow: auto;
  table th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: #e6e1e1b4;
  }

  table {
    border: 2px solid #80808023;
    th,
    td {
      border-left: 2px solid #80808023;
      border-right: 2px solid #80808023;
      padding: 0 5px;
    }

    tr {
      border-bottom: 2px solid #80808023;
      &:hover {
        background-color: #80808023;
      }
    }
  }
}
