.main {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
}

.box {
  width: 430px;
  border-radius: 4px;
  padding: 30px;
  box-shadow: 0px 0px 8px 4px #5353531c;
}

.btnLogin {
  width: 100%;
}
