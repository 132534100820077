// @import "./layout.scss";

:root {
    --background-color: white;
    --font-color: #333;
    --cor-principal: #1890ff;

    transition: background-color 0.3s linear;

    --cor-hover: rgb(185, 220, 250);
    --cor-text: #333;
    --cor-text-inverse: #fff;
    --menu-cor-bg: #012345;
    --menu-cor: #fff;
    --menu-cor-user-icon: #fff;
    --submenu-cor-bg: #15497b;
    --submenu-cor: #fff;
    --submenu-cor-active: #333;

    --cor-menu-separador: #fff;
    --cor-danger: #f5222d;
    --cor-green-light: #d7fdb6;
    --cor-gray: #ddd;
    --cor-gray-light: #bbb;

    --color-selection: #fff;
    --background-selection: #1890ff;
}

::selection {
    color: var(--color-selection);
    background: var(--background-selection);
}

@mixin sticky-pos($prop, $value) {
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    position: sticky;
    #{$prop}: $value;
}
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
    background: #012345;
    color: white;
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
    color: rgba(255, 255, 255, 0.65);
    background: #012345;
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
    background: #01305f;
}

.MuiAppBar-colorPrimary,
.makeStyles-appBar-1 {
    background-color: #012345 !important;
}

.ant-message {
    z-index: 2000;
}
.ant-popover-inner-content {
    padding: 0;
}

.ant-upload.ant-upload-select-picture-card {
    width: 100%;
}

@media (min-width: 600px) {
    .MuiPaper-root .MuiToolbar-regular {
        min-height: initial;
    }
}

.color-all {
    color: rgb(196, 196, 196) !important;
}

.MuiFab-root {
    height: 40px !important;
    width: 40px !important;
}

.container-padrao {
    max-width: 1200px;
    padding-top: 60px;
    padding-right: 7px;
    padding-left: 7px;
    margin: 0 auto;
}

.submenu-light {
    .ant-menu-sub {
        background: white !important;

        .ant-menu-item,
        .ant-menu-item a {
            color: #333;
        }

        .ant-menu-item-active {
            background-color: #eee;
        }
    }
}

.tabela-padrao {
    font-family: "az_ea_font", "Segoe UI", "wf_segoe-ui_semibold", "Segoe UI Semibold", "Segoe WP Semibold", "Segoe WP", Tahoma, Arial, sans-serif;
    font-size: 13px;
    width: 100%;

    thead th,
    tfoot td {
        background-color: #ffffff;
    }

    thead,
    tfoot {
        & > tr {
            font-weight: bold;
        }
    }

    th,
    td {
        padding: 6px 3px;
    }

    tr {
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        transition: all 0.3s ease;

        &:nth-child(1) {
            border-top: 0;
        }

        &:hover {
            background: #dbecfc;
            #icon {
                opacity: 1;
            }

            .td-icon {
                opacity: 1;
                text-align: right;
            }
        }
    }

    tfoot tr {
        border-bottom: none;
    }

    #icon {
        opacity: 0;
        cursor: pointer;
    }
    .td-icon {
        opacity: 0;
        cursor: pointer;
        padding-top: 10px;

        & > *:not(:first-child) {
            margin-left: 8px;
        }
    }
}

.tabela-padrao-branca {
    font-family: "az_ea_font", "Segoe UI", "wf_segoe-ui_semibold", "Segoe UI Semibold", "Segoe WP Semibold", "Segoe WP", Tahoma, Arial, sans-serif;
    font-size: 13px;
    width: 100%;

    thead th,
    tfoot td {
        background-color: white;
    }

    tbody {
        tr {
            &:hover {
                background-color: rgba(85, 179, 255, 0.1);
            }
        }
    }

    th.th-sort {
        cursor: pointer;
        user-select: none;

        &:hover {
            background-color: #f7f7f7;
        }
    }

    th,
    td {
        padding: 6px 3px;
    }

    th,
    tbody td {
        border-bottom: 1px solid #d6d6d6;
    }

    th,
    tfoot td {
        text-transform: uppercase;
        font-weight: 600;
        padding: 10px 3px;
    }

    tr {
        transition: all 0.3s ease;
        &:hover {
            background: #dbecfc;
            #icon {
                opacity: 1;
            }

            .td-icon {
                opacity: 1;
                text-align: right;
            }
        }
    }
    #icon {
        opacity: 0;
        cursor: pointer;
    }
    .td-icon {
        opacity: 0;
        cursor: pointer;
        padding-top: 10px;

        & > *:not(:first-child) {
            margin-left: 8px;
        }
    }
}

textarea {
    resize: none;
}

.row-selected {
    background-color: hsl(214, 100%, 92%);
    color: rgb(0, 35, 110);
}

.popover-padrao {
    & > div {
        clear: both;
        margin: 0;
        padding: 5px 20px;
        color: rgba(0, 0, 0, 0.65);
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        white-space: nowrap;
        cursor: pointer;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;

        &:hover {
            background-color: #e6f7ff;
        }
    }
}

.btn-transparent {
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
}

.viewer {
    .icon {
        transition: all 0.1s ease;
        opacity: 0;
    }
    &:hover {
        .icon {
            opacity: 1;
        }
    }
}

.cad-icon {
    font-size: 1.175em;
}

.cor-primary {
    color: var(--cor-principal);
}
.cor-danger {
    color: #f5222d;
}

.cor-verde-bg {
    background-color: #d7fdb6;
}

.flex-padrao {
    display: flex;
    & > div:not(:last-child) {
        margin-right: 8px;
    }
}

.flex-padrao-sm {
    display: flex;
    & > div:not(:last-child) {
        margin-right: 10px;
    }
}

.modal-titulo {
    display: flex;
    align-items: center;

    & svg {
        margin-top: 10px;
    }
}

.row-verde {
    color: green;
}

.row-vermelho {
    color: rgb(196, 0, 0);
}

.row-amarelo {
    color: #f0750f;
}

.cor-azul-bg {
    background-color: #b6dbfd;
}

.row-azul {
    color: #316ca2;
}

.div-spacing {
    & > * {
        margin-bottom: 10px;
    }
}

.div-spacing-lg {
    & > * {
        margin-bottom: 15px;
    }
}

.sticky-bottom {
    @include sticky-pos(bottom, 0);

    background-color: #eee;
}

.tabela-wrapper {
    overflow-x: auto;

    @media (min-width: 992px) {
        overflow-x: visible;

        table {
            thead {
                th {
                    @include sticky-pos(top, 47px);
                }
            }

            tfoot {
                td {
                    @include sticky-pos(bottom, 0);
                }
            }
        }
    }
}

.tabela-wrapper-zero {
    overflow-x: auto;

    @media (min-width: 992px) {
        overflow-x: visible;

        table {
            thead {
                th {
                    @include sticky-pos(top, -2px);
                }
            }

            tfoot {
                td {
                    @include sticky-pos(bottom, 0);
                }
            }
        }
    }
}
.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.td-nowrap {
    white-space: nowrap;
}

.td-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tr-dragging {
    background-color: #8fc9ff !important;
}

table tbody .tr-marca:nth-child(n) {
    background-color: #ffff80;
}

table tbody .tr-obs:nth-child(n) {
    background-color: #ffcf7e;
}

table tbody tr .tr-cinza {
    background-color: #eee;
}

.btn-cinza {
    color: #999;
    cursor: pointer;
    padding: 4px 8px;
    border-radius: 3px;

    &:hover {
        color: #333;
        background-color: #eee;
    }
}

.list-dragdrop {
    display: flex;
    border: #ddd 1px solid;
    padding: 5px;
    border-radius: 5px;
    transition: 0.2s ease;
    transition-property: background-color;
}

.list-dragdrop-item {
    margin-left: 10px;
    background: rgba(240, 237, 237, 0.37);
    padding: 5px;
    border-radius: 5px;
    transition: 0.5s ease;
    transition-property: background-color;
}

.card-box {
    display: flex;

    .card-item {
        box-shadow: 0 0 7px 3px rgba(221, 221, 221, 0.404);
        min-width: 230px;
        &:nth-child(even) {
            margin: 0 10px;
        }
    }
}

.link-padrao {
    color: #1890ff;
    cursor: pointer;
    &:hover {
        color: #72b8fa;
    }
}

@page {
    size: auto;
    margin: 10px 10px 100px 10px;
}
@media print {
    body {
        -webkit-print-color-adjust: exact;
        padding: 0mm !important;
        -ms-filter: none;
        filter: none;
    }
}

.input-currency {
    padding-left: 25px;
    position: relative;
    &::after {
        content: "R$";
        position: absolute;
        top: 5px;
        left: 15px;
    }
}

.hover-padrao {
    opacity: 0.8;
    transition: 0.5s ease;
    &:hover {
        opacity: 1;
    }
}
