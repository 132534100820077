.gridCard {
  margin-top: 30px;
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 10px;
  .cards {
    height: 85px;
  }
}

@media only screen and (max-width: 750px) {
  .gridCard {
    display: grid;
    grid-template-columns: auto auto;
  }
}

@media only screen and (max-width: 1000px) {
  .filGrid {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 10px;
  }
}
